<template>
  <b-card>
    <monitored-detail
      :fields="[
        { key: 'id', label: 'id', },
        { key: 'merchant', label: 'Estabelecimento'},
        { key: 'type', label: 'tipo' },
        { key: 'message', label: 'Mensagem' },
        { key: 'created_at', label: 'Criado Em' },
      ]"
      :items="itemsReversed"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import MonitoredDetail from './Details/components/MonitoredDetail.vue';

import MerchantMonitoringService from '@/service/transaction_monitoring/ec';

export default {
    components: {
        MonitoredDetail,
        BCard,
    },

    data() {
        return {
            items: [],
        };
    },

    computed: {
        itemsReversed() {
            const { items } = this;

            return items.sort((a, b) => b.id - a.id);
        },
    },

    async mounted() {
        this.callLoading(true);
        await this.monitoredDetail();
        this.callLoading(false);
    },

    methods: {
        async monitoredDetail() {
            const { data: { data } } = await MerchantMonitoringService.monitoredDetails();
            this.items = data;
        },
    },
};
</script>
