<template>
  <div>
    <b-table
      class="text-center"
      :items="items"
      :current-page="page"
      :per-page="8"
      :fields="fields"
    >
      <template #cell(created_at)="data">
        {{ data.item.created_at | date }}
      </template>

      <template #cell(type)="data">
        {{ data.item.monitoring_merchant_types_id === 1 ? 'Monitorado' : 'Término' }}
      </template>

      <template #cell(merchant)="data">
        {{ data.item.merchant.name }}
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="items.length"
      :per-page="8"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import { BTable, BPagination } from 'bootstrap-vue';

import MerchantMonitoringService from '@/service/transaction_monitoring/ec';

export default {
    components: {
        BTable, BPagination,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },

        fields: {
            type: Array,
            default: () => [
                { key: 'id', label: 'id' },
                { key: 'type', label: 'tipo' },
                { key: 'message', label: 'Mensagem' },
                { key: 'created_at', label: 'Criado Em' },
            ],
        },
    },

    data() {
        return {
            page: 1,
        };
    },

    methods: {
        async getData() {
            const { data } = await MerchantMonitoringService.monitoredDetail(this.establishmentId);

            this.items = data;
        },
    },
};
</script>
