import methods from '@/enums/methods';
import api from '../api/thoth';
import TransactionMonitoringService from './base';

class MerchantMonitoringService extends TransactionMonitoringService {
    monitoringMerchant({
        message,
        establishmentId,
    }) {
        return this.fetch({
            url: 'monitoring/merchant/',
            data: {
                message,
                establishment_id: establishmentId,
            },
            method: methods.PUT,
        });
    }

    desmonitoringMerchant(ec, { message }) {
        return this.fetch({
            data: {
                message,
                establishment_id: ec,
            },
            url: `monitoring/merchant/${ec}`,
            method: methods.PUT,
        });
    }

    monitoredDetails() {
        return this.fetch({
            url: 'monitoring/merchant/history',
        });
    }

    monitoredDetail(ec) {
        return this.fetch({
            url: `monitoring/merchant/history/${ec}`,
        });
    }
}

export async function getEc(merchantId) {
    try {
        return await api.get(`/merchant/${merchantId}`);
    } catch ({ response }) {
        return response;
    }
}

export default new MerchantMonitoringService();
